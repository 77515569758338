var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', [_vm.preserveTime ? _c('span', [_c('button', {
    staticClass: "btn _clear",
    on: {
      "click": function click($event) {
        return _vm.downloadSelectedFolder();
      }
    }
  }, [!_vm.ownContent ? _c('div', {
    staticStyle: {
      "color": "#7d91a5"
    }
  }, [_c('i', {
    staticClass: "icon-zip"
  }), _c('span', [_vm._v("  " + _vm._s(_vm.text))])]) : _c('span', [_c('span'), _vm._t("default")], 2)])]) : _c('span', [_c('form', {
    attrs: {
      "action": "https://westeurope1-mediap.svc.ms/transform/zip?cs=fFNQTw",
      "method": "POST",
      "target": "_blank"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.zipFiles,
      expression: "zipFiles"
    }],
    attrs: {
      "type": "hidden",
      "name": "files"
    },
    domProps: {
      "value": _vm.zipFiles
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.zipFiles = $event.target.value;
      }
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "zipFileName"
    },
    domProps: {
      "value": _vm.zipFileName
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "guid",
      "value": "f16e85e1-3ce9-42c9-9ea3-31bb0afab035"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "provider",
      "value": "spo"
    }
  }), _c('button', {
    staticClass: "btn",
    class: [_vm.btnSize, _vm.btnColor],
    attrs: {
      "type": "submit"
    }
  }, [!_vm.ownContent ? _c('div', {
    staticStyle: {
      "color": "#7d91a5"
    }
  }, [_c('i', {
    staticClass: "icon-zip"
  }), _c('span', [_vm._v("  " + _vm._s(_vm.text))])]) : _c('span', [_c('span'), _vm._t("default")], 2)])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };